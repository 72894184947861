import { observer } from 'mobx-react'
import { GetStaticProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useState } from 'react'
import { MyHead } from '@/components/molecules/MyHead'
import { VerticalNavDefaultPageTemplate } from '@/components/templates/VerticalNavDefaultPageTemplate'
import { ForumTwoColumnContentTemplate } from '@/components/templates/ForumTwoColumnContentTemplate'
import { getStaticStores, useStores } from '@/utils'
import { IQuestionBase, Language, QuestionOrderBase } from '@/types'
import { Button } from '@/components/atoms/Button'
import { QuestionList } from '@/components/organisms/QuestionList'
import { ForumFormButton } from '@/components/molecules/ForumFormButton'
import { LoginToAnswerButton } from '@/components/molecules/LoginToAnswerButton'
import { KeywordSearchGroupForQuestions } from '@/components/organisms/KeywordSearchGroupForQuestions'
import { QuestionCategoriesMenuList } from '@/components/organisms/QuestionCategoriesMenuList'
import { ShowMoreOnTopForMb } from '@/components/organisms/ShowMoreOnTopForMb'
import { SortGroupForQuestions } from '@/components/organisms/SortGroupForQuestions'
import { FooterSnsLink } from '@/components/molecules/FooterSnsLink'
import { FooterSubMenu } from '@/components/molecules/FooterSubMenu'
import styles from './index.module.scss'

type Props = {
  staticQuestions: IQuestionBase[]
  staticHasNextQuestionsPage: boolean
}

const NUM_OF_ITEMS = 24

const Home: NextPage<Props> = observer(({ staticQuestions, staticHasNextQuestionsPage }) => {
  const { t } = useTranslation(['questions'])
  const { viewer, questions } = useStores()
  const [searchResults, setSearchResults] = useState<IQuestionBase[]>(
    questions.questions.length > 0 ? questions.questions : staticQuestions
  )
  const [searchKeyword, setSearchKeyword] = useState('')
  const [orderBy, setOrderBy] = useState<QuestionOrderBase>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [mounted, setMounted] = useState(false)

  const searchHandler = async (keyword: string, questionOrder: QuestionOrderBase) => {
    const result = await questions.fetchQuestions({
      searchWord: keyword,
      orderBy: questionOrder,
      limit: NUM_OF_ITEMS,
      shouldRefresh: true,
    })
    setSearchResults(result)
  }

  const fetchQuestions = async () => {
    setIsFetching(true)
    const isFirstTimeFetching = staticQuestions === searchResults
    await questions.fetchQuestions({
      searchWord: searchKeyword,
      orderBy,
      limit: isFirstTimeFetching ? NUM_OF_ITEMS * 2 : NUM_OF_ITEMS, // 初回の追加読み込みは ISR 分も含めて倍読み込む
      shouldRefresh: isFirstTimeFetching, // 初回の追加読み込みは Store の中身をリセットするために shouldRefresh: true
    })
    setSearchResults(questions.questions)
    setIsFetching(false)
  }

  const setSearchValues = async (keyword: string, questionOrder: QuestionOrderBase) => {
    setSearchKeyword(keyword)
    setOrderBy(questionOrder)
    await searchHandler(keyword, questionOrder)
  }

  useEffect(() => {
    setMounted(true)
    // ブラウザ側での初期化時に Store に hasNextPage を設定
    questions.updateHasNextQuestionsPage(staticHasNextQuestionsPage)
  }, [])

  return (
    <VerticalNavDefaultPageTemplate>
      <MyHead title='AQA Forum' description='Open to everyone for Web3 information exchange' />
      <ForumTwoColumnContentTemplate
        aside={
          <div className={styles.aside}>
            <KeywordSearchGroupForQuestions
              search={async (keyword, questionOrder) => setSearchValues(keyword, questionOrder)}
              orderBy={orderBy}
              setSearchKeyword={(word: string) => setSearchKeyword(word)}
            />
            <QuestionCategoriesMenuList />
            <FooterSubMenu />
            <div className={styles.sns}>
              <FooterSnsLink />
            </div>
          </div>
        }
      >
        <div className={styles.form}>{viewer.isSignedIn ? <ForumFormButton /> : <LoginToAnswerButton />}</div>
        <div className={styles.filter}>
          <KeywordSearchGroupForQuestions
            search={async (keyword, questionOrder) => setSearchValues(keyword, questionOrder)}
            orderBy={orderBy}
            setSearchKeyword={(word: string) => setSearchKeyword(word)}
          />
          <ShowMoreOnTopForMb />
        </div>
        <h2 className={styles.heading}>Posts</h2>
        <SortGroupForQuestions
          search={async (keyword, questionOrder) => setSearchValues(keyword, questionOrder)}
          searchKeyword={searchKeyword}
          setOrderBy={(order: QuestionOrderBase) => setOrderBy(order)}
        />
        <QuestionList items={searchResults} />
        {mounted && questions.hasNextQuestionsPage && (
          <div className={styles.button}>
            <Button
              styleType='default'
              componentType='button'
              buttonSize='large'
              isLoading={isFetching}
              onClick={() => fetchQuestions()}
            >
              {t('+ Load More')}
            </Button>
          </div>
        )}
      </ForumTwoColumnContentTemplate>
    </VerticalNavDefaultPageTemplate>
  )
})

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { viewer, questions } = getStaticStores()
  await viewer.changeLanguage(locale as Language)
  const staticQuestions = await questions.fetchQuestions({ limit: NUM_OF_ITEMS, shouldRefresh: true })

  return {
    props: {
      staticQuestions,
      staticHasNextQuestionsPage: questions.hasNextQuestionsPage,
      ...(await serverSideTranslations(locale, ['global', 'validation', 'search', 'p'])),
    },
    revalidate: 1,
  }
}

export default Home
